import React from "react";

const Topbar = () => {
  return(
    <div className="topbar">
      <p>Diego Castro</p>
    </div>
  )
}

export default Topbar;